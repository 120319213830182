import {
  AppRoutes,
  AppSplashScreen,
  DATA_STATE,
  useDocumentTitle,
} from '@codeer/handypark-web-common';
import { APP_ROUTES, MainError, PageLayout, useMain } from '@core';

import { FC } from 'react';

export const Main: FC = () => {
  useDocumentTitle();

  const { mainDataState, mainError } = useMain();

  if (mainDataState === DATA_STATE.LOADING) {
    return <AppSplashScreen />;
  }

  if (mainDataState === DATA_STATE.ERROR) {
    return <MainError error={mainError} />;
  }

  const routes = APP_ROUTES();

  return (
    <PageLayout>
      <AppRoutes appRoutes={routes} />
    </PageLayout>
  );
};
