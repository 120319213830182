import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButtonNext,
  AppContainer,
  AppSpinner,
  getErrorMessages,
} from '@codeer/handypark-web-common';

import { PARKING_CARD_ORIGIN_OPTIONS, ParkingCardOriginRadioButtons } from '@core';

import {
  isParkingCardCountryCodeForeignAtom,
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  PARKING_CARD_COUNTRY_CODE_ROUTES,
  parkingCardCountryCodeAtom,
  SignInButtons,
} from '@features/parking-card-country-code';
import { parkingCardCountryCodeForeignStepAtom } from '@features/parking-card-country-code/state/parking-card-country-code-foreign.state';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  PARKING_CARD_COUNTRY_CODE_FORM_KEYS,
  parkingCardCountryCodeBelgianFormValidationSchema,
  TParkingCountryCodeBelgianForm,
} from './';

type TParkingCardCountryCodeFormProps = {
  setFormErrors: (errors: string[]) => void;
};

export const ParkingCardCountryCodeBelgianForm = ({
  setFormErrors,
}: TParkingCardCountryCodeFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'radio-buttons.parking-card-origin.options',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setParkingCardCountryCodeForeignStep = useSetAtom(parkingCardCountryCodeForeignStepAtom);
  const setIsParkingCardCountryCodeForeign = useSetAtom(isParkingCardCountryCodeForeignAtom);

  const [parkingCardCountryCode, setParkingCardCountryCode] = useAtom(parkingCardCountryCodeAtom);

  const defaultValues = {
    [PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE]:
      parkingCardCountryCode === PARKING_CARD_ORIGIN_OPTIONS.BELGIAN ||
      parkingCardCountryCode === PARKING_CARD_ORIGIN_OPTIONS.OTHER
        ? parkingCardCountryCode
        : PARKING_CARD_ORIGIN_OPTIONS.OTHER,
  };

  const { handleSubmit, control, formState, watch } = useForm<TParkingCountryCodeBelgianForm>({
    resolver: yupResolver(parkingCardCountryCodeBelgianFormValidationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });
  const parkingCardOriginWatcher = watch(
    PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE,
  );

  useEffect(() => {
    if (!formState.isDirty) {
      return;
    }
    setParkingCardCountryCode(parkingCardOriginWatcher);
  }, [formState.isDirty, parkingCardOriginWatcher, setParkingCardCountryCode]);

  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const onSubmitForeign: SubmitHandler<TParkingCountryCodeBelgianForm> = () => {
    resetFormErrors();
    setIsParkingCardCountryCodeForeign(true);
    setParkingCardCountryCodeForeignStep(PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.SELECT_COUNTRY);
    navigate(PARKING_CARD_COUNTRY_CODE_ROUTES().FOREIGN);
  };

  const onError: SubmitErrorHandler<TParkingCountryCodeBelgianForm> = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
  };

  if (isLoading) {
    return (
      <AppContainer my={4} aria-busy={true}>
        <AppSpinner showText={true} label={t('BE.redirect-label')} />
      </AppContainer>
    );
  }

  return (
    <form
      onSubmit={() => {
        if (parkingCardOriginWatcher === PARKING_CARD_ORIGIN_OPTIONS.OTHER) {
          handleSubmit(onSubmitForeign, onError)();
        }
      }}
    >
      <AppContainer my={4}>
        <ParkingCardOriginRadioButtons
          name={PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE}
          control={control}
          showErrorMessage={false}
        />
      </AppContainer>

      <AppContainer>
        {parkingCardOriginWatcher === PARKING_CARD_ORIGIN_OPTIONS.BELGIAN && (
          <SignInButtons setIsLoading={setIsLoading} />
        )}
        {parkingCardOriginWatcher === PARKING_CARD_ORIGIN_OPTIONS.OTHER && (
          <AppButtonNext type={'submit'} mb={4} onClick={handleSubmit(onSubmitForeign, onError)} />
        )}
      </AppContainer>
    </form>
  );
};
