import { IRouteConfig } from '@codeer/handypark-web-common';
import { DEFAULT_ROUTES } from '@core/enums';
import { parkingCardCountryCodeRoutes } from '@features/parking-card-country-code';
import { PlaygroundPage } from '@features/playground';
import { lazy } from 'react';

const NotFoundPage = lazy(() =>
  import('@codeer/handypark-web-common').then(module => ({
    default: module.NotFoundPage,
  })),
);

const defaultRoutes = (): IRouteConfig[] => {
  return [
    {
      path: DEFAULT_ROUTES.NOT_FOUND,
      component: NotFoundPage,
    },
    {
      path: '*',
      component: NotFoundPage,
    },
  ];
};

const devRoutes: IRouteConfig[] = [
  {
    path: '/playground',
    component: PlaygroundPage,
  },
];

export const APP_ROUTES: () => IRouteConfig[] = () => [
  ...(HP.SHOW_DEV_SETTINGS ? devRoutes : []),
  ...parkingCardCountryCodeRoutes(),
  ...defaultRoutes(),
];
